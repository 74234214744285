import React, {useState, useEffect, useReducer, useContext, createContext, useCallback} from 'react';
import produce from "immer";
import _ from "lodash";
import {APICall} from "./api";
import {runScrollToTop} from "./scroll-to-top";
import {useAuth} from "./auth";

const initialBookingState = {
    branchcode: undefined,
    direction: undefined,
    qty: 1,
    bookingdate: undefined,
    bookingslot: undefined,
    reference: undefined,
    reference_valid: false,
    carriercode: undefined,
    containerno: undefined,
    doorposition: undefined,
    pinsposition: undefined,
    regono: undefined,
    isocode: undefined,
    isocode_options: [],
    loadstatus: undefined,
    loadstatus_options: [],
    errors: {}
};

export const initialFieldLabels = {
    branchcode: "Depot",
    direction: "Container Direction",
    qty: "Container Quantity",
    bookingstatus: "Booking Status",
    bookingdate: "Booking Date",
    bookingslot: "Booking Slot",
    reference: "Release Number",
    carriercode: "Carrier",
    containerno: "Container Number",
    doorposition: "Door Position",
    pinsposition: "Pins Position",
    regono: "Rego Number",
    isocode: "ISO Code",
    loadstatus: "Load Status"
};

const bookingReducer = (state, action) => (
    produce(state, draft => {
        const {type, payload} = action;
        if (type === 'RESET_BOOKING') return initialBookingState;
        switch (type) {
            case 'UPDATE_BRANCH_CODE':
                if (draft.branchcode !== payload) {
                    draft.bookingdate = undefined;
                    draft.bookingslot = undefined;
                    draft.reference = undefined;
                    delete draft.errors['bookingdate'];
                    delete draft.errors['bookingslot'];
                    delete draft.errors['reference'];
                }
                draft.branchcode = payload;
                delete draft.errors['branchcode'];
                break;
            case 'UPDATE_CARRIER_ID':
                draft.carriercode = payload;
                delete draft.errors['carriercode'];
                break;
            case 'UPDATE_CONTAINER_DIRECTION':
                if (draft.direction !== payload) {
                    Object.keys(initialBookingState).forEach(key => {
                        if (!['direction', 'branchcode', 'carriercode'].find(k => k === key)) draft[key] = initialBookingState[key];
                    });
                }
                draft.direction = payload;
                break;
            case 'UPDATE_QUANTITY':
                draft.qty = parseInt(payload, 10);
                if (!!state.bookingslot && state.bookingslot.availableqty < payload) {
                    draft.bookingslot = undefined;
                    delete draft.errors['bookingslot'];
                }
                delete draft.errors['qty'];
                break;
            case 'UPDATE_REFERENCE':
                draft.reference = payload;
                delete draft.errors['reference'];
                break;
            case 'UPDATE_REFERENCE_VALID':
                draft.reference_valid = payload;
                break;
            case 'UPDATE_CONTAINER_NO':
                draft.containerno = payload;
                if (!!payload && payload.length) {
                    draft.qty = 1;
                    delete draft.errors['qty'];
                }
                delete draft.errors['containerno'];
                break;
            case 'UPDATE_BOOKING_DATE':
                draft.bookingdate = payload;
                delete draft.errors['bookingdate'];
                break;
            case 'UPDATE_ISO_CODE':
                draft.isocode = payload;
                delete draft.errors['isocode'];
                break;
            case 'UPDATE_ISO_CODE_OPTIONS':
                draft.isocode = undefined;
                delete draft.errors['isocode'];
                const isocode_options = payload.filter((item, i) => payload.indexOf(item) === i);
                draft.isocode_options = isocode_options;
                if (isocode_options.length === 1) draft.isocode = isocode_options[0];
                break;
            case 'UPDATE_LOAD_STATUS_OPTIONS':
                draft.loadstatus = undefined;
                delete draft.errors['loadstatus'];
                const loadstatus_options = payload.filter((item, i) => payload.indexOf(item) === i);
                draft.loadstatus_options = loadstatus_options;
                if (loadstatus_options.length === 1) draft.loadstatus = loadstatus_options[0];
                break;
            case 'UPDATE_REGO_NO':
                draft.regono = payload;
                delete draft.errors['regono'];
                break;
            case 'UPDATE_LOAD_STATUS':
                draft.loadstatus = payload;
                delete draft.errors['loadstatus'];
                break;
            case 'UPDATE_DOOR_POSITION':
                draft.doorposition = payload;
                delete draft.errors['doorposition'];
                break;
            case 'UPDATE_PINS_POSITION':
                draft.pinsposition = payload;
                delete draft.errors['pinsposition'];
                break;
            case 'UPDATE_BOOKING_SLOT':
                draft.bookingslot = payload;
                delete draft.errors['bookingslot'];
                break;
            case 'SET_ERROR':
                draft.errors[payload.field] = payload.error;
                break;
            case 'SET_ERRORS':
                draft.errors = payload;
                break;
            case 'REMOVE_ERROR':
                delete draft.errors[payload.field];
                break;
            case 'CLEAR_ERRORS':
                draft.errors = {};
                break;
            default:
                break;
        }
    })
);


const createBookingContext = createContext();

export function ProvideCreateBooking({ children }) {
    const createBooking = useProvideCreateBooking();
    return <createBookingContext.Provider value={createBooking}>{children}</createBookingContext.Provider>;
}

export const useCreateBooking = () => {
    return useContext(createBookingContext);
};

function useProvideCreateBooking() {
    const {user} = useAuth(),
          [bookingState, dispatch] = useReducer(bookingReducer, initialBookingState),
          [validateLoading, setValidateLoading] = useState(false),
          [saveLoading, setSaveLoading] = useState(false),
          [saveError, setSaveError] = useState(null),
          [saveData, setSaveData] = useState(null),
          [fieldLabels, setFieldLabels] = useState(initialFieldLabels),
          [showSaveModal, setShowSaveModal] = useState(false),
          [showStopModal, setShowStopModal] = useState(false),
          [requireCarrierSelect, setRequireCarrierSelect] = useState(false),
          setRegoNo = useCallback((regono) => dispatch({type: 'UPDATE_REGO_NO', payload: regono.toUpperCase()}), [dispatch]),
          setPinsPosition = useCallback((pinsposition) => dispatch({type: 'UPDATE_PINS_POSITION', payload: pinsposition}), [dispatch]),
          setDoorPosition = useCallback((doorposition) => dispatch({type: 'UPDATE_DOOR_POSITION', payload: doorposition}), [dispatch]),
          setISOCode = useCallback((isocode) => dispatch({type: 'UPDATE_ISO_CODE', payload: isocode}), [dispatch]),
          setISOCodeOptions = useCallback((isocodes) => dispatch({type: 'UPDATE_ISO_CODE_OPTIONS', payload: isocodes}), [dispatch]),
          setLoadStatusOptions = useCallback((loadtstatuses) => dispatch({type: 'UPDATE_LOAD_STATUS_OPTIONS', payload: loadtstatuses}), [dispatch]),
          setLoadStatus = useCallback((loadstatus) => dispatch({type: 'UPDATE_LOAD_STATUS', payload: loadstatus}), [dispatch]),
          setBranchCode = useCallback((branchcode) => dispatch({type: 'UPDATE_BRANCH_CODE', payload: branchcode}), [dispatch]),
          setCarrierCode = useCallback((carriercode) => dispatch({type: 'UPDATE_CARRIER_ID', payload: carriercode}), [dispatch]),
          setDirection = useCallback((direction) => {
              dispatch({type: 'UPDATE_CONTAINER_DIRECTION', payload: direction});
              if (direction === 'IN') {
                  setFieldLabels({
                     ...initialFieldLabels,
                     reference: 'Acceptance Number'
                  });
              }
              if (direction === 'OUT') {
                  setFieldLabels({
                      ...initialFieldLabels
                  });
              }
          }, [setFieldLabels, dispatch]),
          setQty = useCallback((qty) => dispatch({type: 'UPDATE_QUANTITY', payload: qty}), [dispatch]),
          setBookingDate = useCallback((bookingdate) => dispatch({type: 'UPDATE_BOOKING_DATE', payload: bookingdate}), [dispatch]),
          setBookingSlot = useCallback((bookingslot) => dispatch({type: 'UPDATE_BOOKING_SLOT', payload: bookingslot}), [dispatch]),
          setReferenceValid = useCallback((valid) => dispatch({type: 'UPDATE_REFERENCE_VALID', payload: valid}), [dispatch]),
          setReference = useCallback((reference) => dispatch({type: 'UPDATE_REFERENCE', payload: reference.toUpperCase()}), [dispatch]),
          setContainerNo = useCallback((containerno) => dispatch({type: 'UPDATE_CONTAINER_NO', payload: containerno.toUpperCase()}), [dispatch]),
          setFieldError = useCallback(({field, error}) => dispatch({type: 'SET_ERROR', payload: {field, error}}), [dispatch]),
          setFieldErrors = useCallback((errors) => dispatch({type: 'SET_ERRORS', payload: errors}), [dispatch]),
          removeFieldError = useCallback((field) => dispatch({type: 'REMOVE_ERROR', payload: field}), [dispatch]),
          clearFieldErrors = useCallback(() => dispatch({type: 'CLEAR_ERRORS'}), [dispatch]);

    useEffect(() => {
        if (user.administrator === true) {
            setRequireCarrierSelect(true);
        } else {
            setCarrierCode(user.carriercode);
        }
    }, [setCarrierCode, setRequireCarrierSelect, user.administrator, user.carriercode]);

    const resetBooking = useCallback(() => {
        const depot = bookingState.branchcode;

        dispatch({type: 'RESET_BOOKING'});
        setSaveData(null);
        setBranchCode(depot);
        if (!user.administrator) setCarrierCode(user.carriercode);
        setShowSaveModal(false);
        runScrollToTop();
    }, [dispatch, setShowSaveModal, setBranchCode, setSaveData, bookingState.branchcode, setCarrierCode, user.administrator, user.carriercode]);

    const resetStopBooking = useCallback(() => {
        const depot = bookingState.branchcode;

        dispatch({type: 'RESET_BOOKING'});
        setSaveData(null);
        setBranchCode(depot);
        if (!user.administrator) setCarrierCode(user.carriercode);
        setShowStopModal(false);
        runScrollToTop();
    }, [dispatch, setShowStopModal, setBranchCode, setSaveData, bookingState.branchcode, setCarrierCode, user.administrator, user.carriercode]);

    const saveBooking = useCallback(() => {

        setSaveLoading(true);
        setSaveData(null);

        const data = {
            ..._.omit(bookingState, ['errors', 'bookingslot', 'bookingdate', 'direction', 'carriercode']),
            direction: bookingState.direction,
            carriercode: bookingState.carriercode,
            slothour: _.get(bookingState, 'bookingslot.hour', undefined),
            slotnumber: _.get(bookingState, 'bookingslot.number', undefined),
            bookingdateticks: _.get(bookingState, 'bookingdate.date.ticks', undefined)
        };

        APICall({
            method: 'post',
            url: '?MessageType=CreateBooking',
            data: {id: user.id, ...data}
        })
            .then(response => {
                setSaveData(response.data);
                setSaveLoading(false);
            })
            .catch(err => {
                const errors = _.get(err, 'response.data.errors', []);
                const fieldErrors = {};
                if (errors.length) {
                    for (const error of errors) {
                        switch (error.field) {
                            case 'slothour':
                            case 'slotnumber':
                                fieldErrors['bookingslot'] = error.error;
                                break;
                            default:
                                fieldErrors[error.field] = error.error;
                        }
                    }
                    setFieldErrors(fieldErrors);
                }
                setShowSaveModal(false);
                setSaveLoading(false);
            });

    }, [user.id, bookingState, setSaveLoading, setFieldErrors]);


    const validateBooking = useCallback(() => {

        setValidateLoading(true);
        clearFieldErrors();

        const data = {
            ..._.omit(bookingState, ['errors', 'bookingslot', 'bookingdate', 'direction']),
            direction: _.defaultTo(bookingState.direction, ''),
            slothour: _.get(bookingState, 'bookingslot.hour', undefined),
            slotnumber: _.get(bookingState, 'bookingslot.number', undefined),
            bookingdateticks: _.get(bookingState, 'bookingdate.date.ticks', undefined)
        };

        APICall({
            method: 'post',
            url: '?MessageType=ValidateBooking',
            data: {id: user.id, ...data}
        })
            .then(response => {
                if (data.direction === 'IN' && !!data.containerno) {
                    const isos = _.get(response, 'data.isos');
                    const reference = _.get(response, 'data.reference');

                    if (!!isos && isos.length === 1) {
                        setISOCodeOptions(isos);
                        setISOCode(isos[0]);
                    }
                    if (!!reference) {
                        setReference(reference);
                    }
                }
                setShowSaveModal(true);
                setValidateLoading(false);
            })
            .catch(err => {
                const errors = _.get(err, 'response.data.errors', []);
                const fieldErrors = {};
                let stop_error = false;
                if (errors.length) {
                    if(bookingState.branchcode === 'PC' && errors.length === 1 && errors[0].field === 'VBC'){
                        const stoparray = errors[0].error.split(";");
                        let stoperrortext = '';
                        let stoperrorobject = '';
                        stoparray.forEach(function(stopItem){
                            if(stopItem === 'Stops present' && stop_error === false){
                                stop_error = true;
                            } else {
                                try {
                                    let jsonData = JSON.parse(stopItem);
                                    stoperrorobject = jsonData;
                                } catch (e) {
                                    if(stoperrortext.length < 2){
                                        stoperrortext = stopItem;
                                    } else {
                                        stoperrortext = stoperrortext+'\n'+stopItem;
                                    }

                                }
                            }

                        });
                        if(stop_error){
                            setFieldErrors({stops:stoperrorobject, stopsother:stoperrortext});
                        }
                    }

                    if(stop_error) {
                        setShowStopModal(true);
                        setValidateLoading(false);
                    } else{
                        if(bookingState.branchcode === 'PC'){
                            setShowStopModal(true);
                        }
                        for (const error of errors) {
                            switch (error.field) {
                                case 'slothour':
                                case 'slotnumber':
                                    fieldErrors['bookingslot'] = error.error;
                                    break;
                                default:
                                    fieldErrors[error.field] = error.error;
                            }
                        }
                        setFieldErrors(fieldErrors);
                    }

                }
                setValidateLoading(false);
            });

    }, [user.id, bookingState, setISOCode, setISOCodeOptions, setReference, setValidateLoading, setFieldErrors, clearFieldErrors]);

    return {
        user,
        bookingState,
        dispatch,
        fieldLabels,

        setBranchCode,
        setCarrierCode,
        setDirection,
        setQty,
        setBookingDate,
        setBookingSlot,
        setReference,
        setReferenceValid,
        setContainerNo,
        setRegoNo,
        setISOCode,
        setISOCodeOptions,
        setPinsPosition,
        setDoorPosition,
        setLoadStatus,
        setLoadStatusOptions,

        requireCarrierSelect,

        setFieldError,
        removeFieldError,

        validateBooking,
        saveBooking,
        saveLoading,
        setSaveLoading,
        validateLoading,
        setValidateLoading,
        saveData,
        setSaveData,
        saveError,
        setSaveError,
        showSaveModal,
        setShowSaveModal,
        showStopModal,
        setShowStopModal,

        resetBooking,
        resetStopBooking,
    };
}
