import React from "react";
import _ from 'lodash';
import {
    Alert,
    Button,
    ButtonToolbar
} from "react-bootstrap";
import Loader from "../../../components/layout/Loader";

import "./styles.scss";
import CreateBookingSection from "../../bookings-new/CreateBookingSection";
import {useBookingSlotsData} from "../../../util/data-hooks";
import PropTypes from "prop-types";

const UpdateDate = ({booking, setBookingDate, setBookingSlot}) => {

    const [bookingSlotsLoading, bookingSlotsData, bookingSlotsError] = useBookingSlotsData(booking.branchcode, booking.bookingdate);

    const onDateClick = (date) => {
        setBookingSlot(undefined);
        setBookingDate(date);
    };

    let releaseWarning = false;

    if (booking.direction === 'OUT') {
        if (booking.branchcode === 'SF' && !booking.reference_valid) {
            releaseWarning = 'Enter a valid release number before choosing a date';
        }
        if (booking.branchcode === 'PC') {
            if (!booking.containerno && !booking.reference_valid) {
                releaseWarning = 'Enter a valid release/container number before choosing a date';
            }
        }
    }

    return (
        <CreateBookingSection title="Date">
            {
                !booking.branchcode || !booking.slotdescription ?
                    <Alert variant="warning">Select a depot and direction before choosing a date</Alert>
                    :
                    releaseWarning ?
                        <Alert variant="warning">
                            {releaseWarning}
                        </Alert>
                        :
                        bookingSlotsError ?
                            <Alert variant="danger">{bookingSlotsError}</Alert>
                            :
                            bookingSlotsLoading ?
                                <Loader align="left" />
                                :
                                <>
                                    <ButtonToolbar className="create-booking-date-buttons">
                                        {
                                            bookingSlotsData.map((available_date, i) => {
                                                const active = !_.isEmpty(booking.bookingdate) && booking.bookingdate.date.base === available_date.date.base;
                                                return (
                                                    <Button key={`date-${i}`}
                                                            variant={active ? 'primary' : 'light'}
                                                            onClick={() => onDateClick(available_date)}>
                                                        {available_date.date.day_name}<br />
                                                        <strong>{available_date.date.day} {available_date.date.month}</strong><br />
                                                        {available_date.date.year}
                                                    </Button>
                                                );
                                            })
                                        }
                                    </ButtonToolbar>
                                    {/*<div className={`invalid-feedback ${!!booking.errors.bookingdate ? 'd-block' : ''}`}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} /> {booking.errors.bookingdate}
                                    </div>*/}
                                </>
            }
        </CreateBookingSection>
    );
};

UpdateDate.propTypes = {
    booking: PropTypes.object
};

export default UpdateDate;
