import React, { useState } from "react";
import { Alert, Button, Col, Form, FormCheck, FormControl, FormLabel, Modal, ModalBody, Row } from "react-bootstrap";
import "./styles.scss";
import {useHistory} from 'react-router-dom';
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const StopFormContent = (props) => {
    let history = useHistory();
    const link = <a href="https://www.portotago.co.nz/marine-and-shipping/schedule-of-charges/">Link</a>
    const customerServicesLink = <a href="mailto:CustomerServices@portotago.co.nz">CustomerServices@portotago.co.nz</a>

    const [acknowledge, setAcknowledge] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = () => {
        if(!acknowledge && !authorized) setError("No Checkboxes have been selected");
        if(acknowledge && !authorized) setError("Only acknowledge checkbox selected. You need to confirm that you are authorized");
        if(!acknowledge && authorized) setError("Only authorized checkbox selected. You need to confirm that you acknowledge");
        if(acknowledge && authorized) history.push('/bookings/create');
    }

    return(
        <Modal show={props.show} onHide={props.hide} size="lg" centered>
            <ModalHeader closeButton>
                <Modal.Title>Stop Form</Modal.Title>
            </ModalHeader>
            <ModalBody>
                <div className="container-fluid">
                    <Form>
                        <Row>
                            <Col lg={2}>
                                <FormLabel htmlFor="container-no" className="form-labels">Container Numbers</FormLabel>
                                <FormLabel htmlFor="arrival-date" className="form-labels">Date of Arrivasl</FormLabel>
                                <FormLabel htmlFor="arrival-time" className="form-labels">Time of Arrival</FormLabel>
                                <FormLabel htmlFor="stops" className="form-labels">List of Stops</FormLabel>
                            </Col>
                            <Col lg={8}>
                                <FormControl id="container-no" value="Container No." readOnly className="mb-3"></FormControl>
                                <FormControl id="arrival-date" value="Date of Arrival" readOnly className="mb-3"></FormControl>
                                <FormControl id="arrival-time" value="Time of Arrival" readOnly className="mb-3"></FormControl>
                                <FormControl id="stops" as="textarea" rows={3} value="List of Stops" readOnly className="mb-3"></FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={10}>
                                <Alert variant="info" id="refer" className="mb-3">
                                    Please refer to Port Otago's website for the Import Storage Costs via the link below 
                                    <br/>{link}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={10}>
                                <Alert variant="info" htmlFor="acknowledge">
                                    I have accessed the link and acknowledge the Import Storage Charges
                                </Alert>
                            </Col>
                            <Col lg={2}>
                                <FormCheck 
                                    id="acknowledge" 
                                    name="acknowledge" 
                                    reverse="true"
                                    required
                                    checked={acknowledge} 
                                    onChange={e => setAcknowledge(e.target.checked)}>
                                </FormCheck>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={10}>
                                <Alert variant="info" htmlFor="authorized">
                                    I am authorized to accept these charges
                                </Alert>
                            </Col>
                            <Col lg={2}>
                                <FormCheck 
                                    id="authorized" 
                                    name="authorized" 
                                    reverse="true" 
                                    required
                                    checked={authorized} 
                                    onChange={e => setAuthorized(e.target.checked)}>
                                </FormCheck>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={10}>
                                <Alert variant="info">
                                    If you have any queries, please contact Customer Services:<br/>
                                    <strong>{customerServicesLink}</strong><br/><br/>
                                    Customer Services <a href="tel:+6434729876">+64 3 472 9876</a> (Mon-Fri, Hrs 8:30 – 17:00)<br/>
                                    24 Hour General Enquiries <a href="tel:+6434727890">+64 3 472 7890</a><br/>
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={handleSubmit}>Submit</Button>
                                {error
                                    ?<Alert variant="warning">{error}</Alert>
                                    : null
                                }
                                
                            </Col>
                        </Row>
                    </Form>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default StopFormContent;