import DashboardPage from './pages/dashboard';
import ContainerEnquiryPage from './pages/container-enquiry';
import CreateBookingPage from './pages/bookings-new';
import StopFormPage from './pages/stop-form';
import ViewBookingsPage from './pages/bookings';
import ImportPendingContainersPage from './pages/import-pending-containers';
import UsersPage from './pages/users';
import EditUserPage from './pages/users-edit';
import Error404 from './pages/error404';
import withAuth from "./components/auth/withAuth";
import PendingContainersPage from "./pages/pending-containers";
import ReleaseEnquiryPage from "./pages/release-enquiry";

export const routes = [
    {
        path: "/",
        exact: true,
        component: DashboardPage,
        admin_only: false
    },
    {
        path: "/container-enquiry",
        exact: true,
        component: withAuth(ContainerEnquiryPage),
        permission: 'prearrivalcontainerenquiry',
        admin_only: false
    },
    {
        path: "/bookings",
        exact: true,
        component: withAuth(ViewBookingsPage),
        permission: 'getbookings',
        admin_only: false
    },
    {
        path: "/bookings/create",
        exact: true,
        component: withAuth(CreateBookingPage),
        permission: 'createbooking',
        admin_only: false
    },
    {
        path: "/bookings/stop-form",
        exact: true,
        component: withAuth(StopFormPage),
        permission: 'createbooking',
        admin_only: false
    },
    {
        path: "/containers/pending",
        exact: true,
        component: withAuth(PendingContainersPage),
        permission: 'getpendingcontainers',
        admin_only: false
    },
    {
        path: "/containers/import-pending",
        exact: true,
        component: withAuth(ImportPendingContainersPage),
        permission: 'importpendingcontainers',
        admin_only: false
    },
    {
        path: "/containers/release-enquiry",
        exact: true,
        component: withAuth(ReleaseEnquiryPage),
        permission: 'releaseenquiry',
        admin_only: false
    },
    {
        path: "/containers/container-enquiry",
        exact: true,
        component: withAuth(ContainerEnquiryPage),
        permission: 'prearrivalcontainerenquiry',
        admin_only: false
    },
    {
        path: "/users",
        exact: true,
        component: withAuth(UsersPage),
        admin_only: true
    },
    {
        path: "/users/create",
        exact: true,
        component: withAuth(EditUserPage),
        admin_only: true
    },
    {
        path: "/users/edit/:userlogin",
        exact: true,
        component: withAuth(EditUserPage),
        admin_only: true
    },
    {
        component: withAuth(Error404)
    }
];
