import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./styles.scss";
import PageTitle from "../../components/layout/PageTitle";
import {APICall, APICancelRequest} from "../../util/api";
import {dateToTicks} from "../../util/date";
import moment from "moment";
import {Alert, Button} from "react-bootstrap";
import Loader from "../../components/layout/Loader";
import {useBranchesData, useCarriersData} from "../../util/data-hooks";
import {Link} from "react-router-dom";
import BookingsBarChart from "../../components/charts/BookingsBarChart";
import BookingsTable from "../bookings/BookingsTable";
import {useAuth} from "../../util/auth";
import _ from 'lodash';
import {useInterval} from "react-use";
import withAuth from "../../components/auth/withAuth";
import {application} from "../../util/application";

const DashboardPage = () => {

    const {user, userHasPermission} = useAuth(),
          [branchesLoading, branchesData, branchesError] = useBranchesData(true),
          [carriersLoading, carriersData, carriersError] = useCarriersData();

    const userHasGetBookings = useMemo(() => userHasPermission('getbookings'), [userHasPermission]);
    const userHasCreateBooking = useMemo(() => userHasPermission('createbooking'), [userHasPermission]);

    const [branchAlertPC, setBranchAlertPC] = useState(null);
    const [branchAlertSF, setBranchAlertSF] = useState(null);
    const [branchAlertRV, setBranchAlertRV] = useState(null);

    const [slotsLoadingPC, setSlotsLoadingPC] = useState(true);
    const [slotsPC, setSlotsPC] = useState(null);

    const [slotsLoadingSF, setSlotsLoadingSF] = useState(true);
    const [slotsSF, setSlotsSF] = useState(null);

    const [slotsLoadingRV, setSlotsLoadingRV] = useState(true);
    const [slotsRV, setSlotsRV] = useState(null);

    const [bookingsLoadingPC, setBookingsLoadingPC] = useState(true);
    const [bookingsPC, setBookingsPC] = useState(null);

    const [bookingsLoadingSF, setBookingsLoadingSF] = useState(true);
    const [bookingsSF, setBookingsSF] = useState(null);

    const [bookingsLoadingRV, setBookingsLoadingRV] = useState(true);
    const [bookingsRV, setBookingsRV] = useState(null);

    const getSlots = useCallback((id, dateticks, branchcode, cancel_token) => {

        if (branchcode === "PC") {
            setSlotsLoadingPC(true);
        }
        if (branchcode === "SF") {
            setSlotsLoadingSF(true);
        }
        if (branchcode === "RV") {
            setSlotsLoadingRV(true);
        }

        APICall({
            method: 'post',
            url: '?MessageType=GetSlots',
            data: {id, dateticks, branchcode},
            cancel_token
        }).then(response => {
            const slots = [];
            const branch_alert = _.get(response, 'data.alert', null);
            response.data.slots.forEach(slot => {
                const s = slots.findIndex(dbslot => dbslot.name === slot.description);
                if (s > -1) {
                    if (['IN', 'BOTH'].find(direction => direction === slot.direction.toUpperCase())) {
                        slots[s].in_available = slot.maxqty - slot.bookedqty;
                        slots[s].in_booked = slot.bookedqty;
                    }
                    if (['OUT', 'BOTH'].find(direction => direction === slot.direction.toUpperCase())) {
                        slots[s].out_available = slot.maxqty - slot.bookedqty;
                        slots[s].out_booked = slot.bookedqty;
                    }
                } else {
                    const slotdata = {
                        name: slot.description,
                        axis_name: slot.description.replace(/[^0-9].+$/, ''),
                        in_label: 'IN',
                        out_label: 'OUT'
                    };

                    if (['IN', 'BOTH'].find(direction => direction === slot.direction.toUpperCase())) {
                        slotdata.in_available = slot.maxqty - slot.bookedqty;
                        slotdata.in_booked = slot.bookedqty;
                    }
                    if (['OUT', 'BOTH'].find(direction => direction === slot.direction.toUpperCase())) {
                        slotdata.out_available = slot.maxqty - slot.bookedqty;
                        slotdata.out_booked = slot.bookedqty;
                    }
                    slots.push(slotdata);
                }
            });

            if (branchcode === "PC") {
                if (!!branch_alert) setBranchAlertPC(branch_alert);
                setSlotsPC(slots);
                setSlotsLoadingPC(false);
            }
            if (branchcode === "SF") {
                if (!!branch_alert) setBranchAlertSF(branch_alert);
                setSlotsSF(slots);
                setSlotsLoadingSF(false);
            }
            if (branchcode === "RV") {
                if (!!branch_alert) setBranchAlertRV(branch_alert);
                setSlotsRV(slots);
                setSlotsLoadingRV(false);
            }

            if (branchcode === "RV") {
                if (!!branch_alert) setBranchAlertRV(branch_alert);
                setSlotsRV(slots);
                setSlotsLoadingRV(false);
            }

        }).catch(err => {

        });
    }, [setSlotsPC, setSlotsSF, setSlotsRV]);

    const getBookings = (id, fromticks, toticks, cancel_token, showLoading=true) => {

        if (showLoading) {
            setBookingsLoadingPC(true);
            setBookingsLoadingSF(true);
            setBookingsLoadingRV(true);
        }

        APICall({
            method: 'post',
            url: '?MessageType=GetBookings',
            data: {id, fromticks, toticks},
            cancel_token
        }).then(response => {
            setBookingsPC(response.data.bookings.filter(booking => booking.branchcode === 'PC'));
            setBookingsLoadingPC(false);
            setBookingsSF(response.data.bookings.filter(booking => booking.branchcode === 'SF'));
            setBookingsLoadingSF(false);
            setBookingsRV(response.data.bookings.filter(booking => booking.branchcode === 'RV'));
            setBookingsLoadingRV(false);
        }).catch(err => {

        });
    };

    useEffect(() => {

        const [cancel_token, cancelRequest] = APICancelRequest(),
            now = moment(),
            fromDateTicks = dateToTicks(now.startOf('day').toDate()),
            toDateTicks = dateToTicks(now.endOf('day').toDate());

        if (userHasGetBookings) {
            if (!branchesLoading) {
                getSlots(user.id, fromDateTicks, "PC", cancel_token);
                getSlots(user.id, fromDateTicks, "SF", cancel_token);
                getSlots(user.id, fromDateTicks, "RV", cancel_token);
                getBookings(user.id, fromDateTicks, toDateTicks, cancel_token);
            }
        }

        return () => cancelRequest('Cancelled request');

    }, [getSlots, branchesLoading, user.id, userHasPermission, userHasGetBookings]);



    useInterval(() => {
        const [cancel_token] = APICancelRequest(),
              now = moment(),
              fromDateTicks = dateToTicks(now.startOf('day').toDate()),
              toDateTicks = dateToTicks(now.endOf('day').toDate());

        getSlots(user.id, fromDateTicks, "PC", cancel_token);
        getSlots(user.id, fromDateTicks, "SF", cancel_token);
        getSlots(user.id, fromDateTicks, "RV", cancel_token);
        getBookings(user.id, fromDateTicks, toDateTicks, cancel_token, false);

    }, application.dashboard_refresh_interval);

    const dashboardTableLayout = () => {
        if(branchesData.length > 1){
            if(branchesData.length > 2){
                return 'table-three col-md-4 mt-4 mt-md-0';
            }
            return 'table-one-two col-md-6 mt-4 mt-md-0';
        }

        return 'table-one-two col-md-12';
    }

    return (
        <>
            <PageTitle title="Dashboard" />
            <div className="container-fluid dashboard-container">
                {
                    branchesLoading ?
                        <Loader />
                        :
                        !!branchesError ?
                            <Alert variant="danger">Error fetching depot data</Alert>
                            :
                            <>
                                {
                                    !branchesData.length ?
                                        <Alert variant="danger">You do not have a depot associated with your account. Please contact an administrator for more
                                            information.</Alert>
                                        :
                                        <div className="row">
                                            {
                                                branchesData.map((branch, i) => {
                                                    return (
                                                        <div key={`dashboard-branch-${i}`}
                                                             className={dashboardTableLayout()}>
                                                            <h2>{branch.name}</h2>
                                                            {
                                                                branch.branchcode === 'PC' && !!branchAlertPC &&
                                                                <Alert variant="info">
                                                                    {
                                                                        branchAlertPC.split('.').map((sentence, s) => <p key={`sen-${s}`}>{sentence}</p>)
                                                                    }
                                                                </Alert>
                                                            }
                                                            {
                                                                branch.branchcode === 'SF' && !!branchAlertSF &&
                                                                <Alert variant="info">
                                                                    {
                                                                        branchAlertSF.split('.').map((sentence, s) => <p key={`sen-${s}`}>{sentence}</p>)
                                                                    }
                                                                </Alert>
                                                            }
                                                            {
                                                                branch.branchcode === 'RV' && !!branchAlertRV &&
                                                                <Alert variant="info">
                                                                    {
                                                                        branchAlertRV.split('.').map((sentence, s) => <p key={`sen-${s}`}>{sentence}</p>)
                                                                    }
                                                                </Alert>
                                                            }
                                                            <div className="dashboard-branch-links">
                                                                {
                                                                    userHasCreateBooking &&
                                                                    <Button size="sm" variant="primary" as={Link}
                                                                            to={`/bookings/create?depot=${branch.branchcode}`}>Create Booking</Button>
                                                                }
                                                                {' '}
                                                                {
                                                                    userHasGetBookings &&
                                                                    <Button size="sm" variant="primary" as={Link} to={`/bookings?depot=${branch.branchcode}`}>View
                                                                        Bookings</Button>
                                                                }
                                                            </div>
                                                            {
                                                                <>
                                                                    <div className="dashboard-availability">
                                                                        <h4>Today's Booking Availability</h4>
                                                                        {
                                                                            branch.branchcode === 'PC' ?
                                                                                !slotsLoadingPC ?
                                                                                    !!slotsPC && slotsPC.length ?
                                                                                        <BookingsBarChart branchcode={branch.branchcode} data={slotsPC}/>
                                                                                        :
                                                                                        <Alert variant="warning">There are no slots available today</Alert>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            branch.branchcode === 'SF' ?
                                                                                !slotsLoadingSF ?
                                                                                    !!slotsSF && slotsSF.length ?
                                                                                        <BookingsBarChart branchcode={branch.branchcode} data={slotsSF}/>
                                                                                        :
                                                                                        <Alert variant="warning">There are no slots available today</Alert>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            branch.branchcode === 'RV' ?
                                                                                !slotsLoadingRV ?
                                                                                    !!slotsRV && slotsRV.length ?
                                                                                        <BookingsBarChart branchcode={branch.branchcode} data={slotsRV}/>
                                                                                        :
                                                                                        <Alert variant="warning">There are no slots available today</Alert>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div className="dashboard-bookings">
                                                                        <h4>Today's Bookings</h4>
                                                                        {
                                                                            branch.branchcode === 'PC' ?
                                                                                !bookingsLoadingPC ?
                                                                                    !!bookingsPC && bookingsPC.length ?
                                                                                        <BookingsTable
                                                                                            bookingsData={bookingsPC}
                                                                                            carriersLoading={carriersLoading}
                                                                                            carriersData={carriersData}
                                                                                            carriersError={carriersError}
                                                                                            branchesLoading={branchesLoading}
                                                                                            branchesData={branchesData}
                                                                                            branchesError={branchesError}
                                                                                            compactTable/>
                                                                                        :
                                                                                        <Alert variant="warning">There are no bookings today</Alert>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            branch.branchcode === 'SF' ?
                                                                                !bookingsLoadingSF ?
                                                                                    !!bookingsSF && bookingsSF.length ?
                                                                                        <BookingsTable
                                                                                            bookingsData={bookingsSF}
                                                                                            carriersLoading={carriersLoading}
                                                                                            carriersData={carriersData}
                                                                                            carriersError={carriersError}
                                                                                            branchesLoading={branchesLoading}
                                                                                            branchesData={branchesData}
                                                                                            branchesError={branchesError}
                                                                                            compactTable/>
                                                                                        :
                                                                                        <Alert variant="warning">There are no bookings today</Alert>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            branch.branchcode === 'RV' ?
                                                                                !bookingsLoadingRV ?
                                                                                    !!bookingsRV && bookingsRV.length ?
                                                                                        <BookingsTable
                                                                                            bookingsData={bookingsRV}
                                                                                            carriersLoading={carriersLoading}
                                                                                            carriersData={carriersData}
                                                                                            carriersError={carriersError}
                                                                                            branchesLoading={branchesLoading}
                                                                                            branchesData={branchesData}
                                                                                            branchesError={branchesError}
                                                                                            compactTable/>
                                                                                        :
                                                                                        <Alert variant="warning">There are no bookings today</Alert>
                                                                                    :
                                                                                    null
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                }
                            </>
                }
            </div>
        </>
    );
};

export default withAuth(DashboardPage);
