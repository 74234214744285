import React, {useState} from 'react';
import PropTypes from "prop-types";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Table} from "react-bootstrap";

import './styles.scss';

export const TableCollapsible = (props) => {

    return (
        <Table {...props} className={`table-collapsible ${props.className ? props.className : ''}`}>
            {props.children}
        </Table>
    );

};

export const TableCollapsibleRow = ({className, children}) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <tr className={className + ' ' + (expanded ? 'expanded' : '')}>
            <td className="table-expand-icon" onClick={() => setExpanded(!expanded)}><FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} /></td>
            {children}
        </tr>
    );
};

TableCollapsible.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

TableCollapsibleRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
