import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Alert, Button, Col, Form, FormCheck, FormControl, FormLabel, Modal, Row} from "react-bootstrap";

import "./styles.scss";
import FormButton from "../../forms/FormButton";
import {useCreateBooking} from "../../../util/bookings";
import stopEmail from "../../../util/stopEmail.js";
import FieldSummary from "../../../pages/bookings-new/FieldSummary";

const StopConfirmModal = (props) => {
    const bookingState = props.bookingState;
    const carriersLoading = props.carriersLoading;
    const carriersData = props.carriersData;
    const branchesLoading = props.branchesLoading;
    const branchesData = props.branchesData;

    const link = <a href="https://www.portotago.co.nz/marine-and-shipping/schedule-of-charges/" target="_blank" rel="noopener noreferrer">link</a>;
    const customerServicesLink = <a href="mailto:CustomerServices@portotago.co.nz" target="_blank" rel="noopener noreferrer">CustomerServices@portotago.co.nz</a>;

    const [acknowledge, setAcknowledge] = useState(false);
    const [importStop, setImportStop] = useState(false);
    const [importStopAlt, setImportStopAlt] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState("");
    const [mailResponse, setMailResponse] = useState(false);
    const [mailSent, setMailSent] = useState(false);

    useEffect(() => {
        if (bookingState.errors.stops) {
            setImportStopAlt(false);
            if (bookingState.errors.stops.length === 1 && bookingState.errors.stops[0].stop_code === "IMPST") {
                if(bookingState.errors.stopsother && bookingState.errors.stopsother.length > 0){
                    setImportStopAlt(true);
                    setImportStop(true);
                } else {
                    setImportStop(true);
                }
            } else {
                let hasImportStorage = false;
                for (let stopItem of bookingState.errors.stops) {
                    if(stopItem.stop_code === "IMPST"){
                        hasImportStorage = true
                    }
                }
                if (hasImportStorage) {
                    setImportStop(true);
                    setImportStopAlt(true);
                } else {
                    setImportStop(false);
                }
            }
        } else {
            setImportStop(false);
        }
    }, [bookingState]);

    const sendEmail = async () => {
        let response = await stopEmail(bookingState.containerno, bookingState.carriercode, props.user);
        setMailResponse(response);
    }

    const handleSubmit = () => {
        if (!acknowledge && !authorized) setError("No Checkboxes have been selected");
        if (acknowledge && !authorized) setError("Only acknowledge checkbox selected. You need to confirm that you are authorized");
        if (!acknowledge && authorized) setError("Only authorized checkbox selected. You need to confirm that you acknowledge");
        if (acknowledge && authorized) {
            setError("");
            setAcknowledge(false);
            setAuthorized(false);
            setCompleted(true);

            sendEmail();
        }
    };

    useEffect(() => {
        setMailSent(true);
    }, [mailResponse]);

    const dateText = () => {
        let date = "";
        if (bookingState.bookingdate) {
            date += (bookingState.bookingdate.date.day_name) ? bookingState.bookingdate.date.day_name + " " : "";
            date += (bookingState.bookingdate.date.day) ? bookingState.bookingdate.date.day + " " : "";
            date += (bookingState.bookingdate.date.month) ? bookingState.bookingdate.date.month + ", " : "";
            date += (bookingState.bookingdate.date.year) ? bookingState.bookingdate.date.year : "";
        }

        return date;
    };

    const stopText = () => {
        let stop = "";
        if (bookingState.errors.stops) {
            if (bookingState.errors.stopsother) {
                stop += bookingState.errors.stopsother + "\n\n";
            }
            for (let stopItem of bookingState.errors.stops) {
                stop += "Code: " + stopItem.stop_code + " - Description: " + stopItem.stop_description + "\n";
            }

        } else {
            for (const key in bookingState.errors) {
                if(key === 'VBC'){
                    let vbcErrorArray = bookingState.errors[key].split(";");
                    vbcErrorArray.forEach(function(vbcErrorItem) {
                        stop += `${vbcErrorItem}\n`;
                    });
                } else {
                    stop += `${key}: ${bookingState.errors[key]}\n`;
                }

            }
        }

        return stop;
    };

    const ContainerReferenceLabel = () => {
        return (bookingState.containerno !== '') ? 'Container Number' : 'Release Number';
    }

    const ContainerReferenceValue = () => {
        return (bookingState.containerno !== '') ? bookingState.containerno : bookingState.reference;
    }

    const cancelModal = () => {
        setAcknowledge(false);
        setAuthorized(false);
        setError("");
        setCompleted(false);
        props.closeModal();
    };

    const DefaultIssueMessage = () => {
        return <Row>
            <Col lg={10}>
                <Alert variant="danger">
                    There are issues or holds on the container that are preventing the VBS booking.<br/>
                    Please contact the relevant party directly to resolve these issues/holds.<br/><br/>
                    If you have any queries, please contact Customer Services:<br/>
                    <strong>{customerServicesLink}</strong><br/><br/>
                    Customer Services <a href="tel:+6434729876">+64 3 472 9876</a> (Mon-Fri, Hrs 8:30 – 17:00)<br/>
                    24 Hour General Enquiries <a href="tel:+6434727890">+64 3 472 7890</a><br/>
                </Alert>
            </Col>
        </Row>
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            backdrop="static"
            centered>
            <Modal.Header>
                <h2>There are issues which prevent you from making this booking.</h2>
            </Modal.Header>
            <Modal.Body>
                {
                    !!props.alert &&
                    <>{props.alert}</>
                }
                <div className="container-fluid">
                    {
                        !completed ?
                            <Form>
                                {error
                                    ? <Alert variant="warning">{error}</Alert>
                                    : null
                                }
                                <Row>
                                    <Col lg={2}>
                                        <FormLabel htmlFor="container-no" className="form-labels">{ContainerReferenceLabel()}</FormLabel>
                                        <FormLabel htmlFor="arrival-date" className="form-labels">Date of Arrival</FormLabel>
                                        <FormLabel htmlFor="arrival-time" className="form-labels">Time of Arrival</FormLabel>
                                        <FormLabel htmlFor="stops" className="form-labels">List of Issues/Holds</FormLabel>
                                    </Col>
                                    <Col lg={8}>
                                        <FormControl id="container-no" value={ContainerReferenceValue()} readOnly className="mb-3"></FormControl>
                                        <FormControl id="arrival-date" value={dateText()} readOnly className="mb-3"></FormControl>
                                        <FormControl id="arrival-time" value={(bookingState.bookingslot) ? bookingState.bookingslot.description : ""} readOnly className="mb-3"></FormControl>
                                        <FormControl id="stops" as="textarea" rows={4} value={stopText()} readOnly className="mb-3"></FormControl>
                                    </Col>
                                </Row>
                                {importStop ?
                                    <>
                                        { importStopAlt &&
                                          DefaultIssueMessage()
                                        }
                                        <Row>
                                            <Col lg={10}>
                                                <Alert variant={importStopAlt ? "warning" : "info"} id="refer" className="mb-3">
                                                    Please refer to Port Otago's website for the Import Storage Costs via this {link}
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <Alert variant={importStopAlt ? "warning" : "info"} htmlFor="acknowledge">
                                                    I have accessed the link and acknowledge the Import Storage Charges
                                                </Alert>
                                            </Col>
                                            <Col lg={2}>
                                                <FormCheck
                                                    id="acknowledge"
                                                    name="acknowledge"
                                                    reverse="true"
                                                    required
                                                    checked={acknowledge}
                                                    onChange={e => setAcknowledge(e.target.checked)}>
                                                </FormCheck>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <Alert variant={importStopAlt ? "warning" : "info"} htmlFor="authorized">
                                                    I am authorized to accept these charges
                                                </Alert>
                                            </Col>
                                            <Col lg={2}>
                                                <FormCheck
                                                    id="authorized"
                                                    name="authorized"
                                                    reverse="true"
                                                    required
                                                    checked={authorized}
                                                    onChange={e => setAuthorized(e.target.checked)}>
                                                </FormCheck>
                                            </Col>
                                        </Row>
                                        { !importStopAlt &&
                                            <Row>
                                                <Col lg={10}>
                                                    <Alert variant={importStopAlt ? "warning" : "info"}>
                                                        If you have any queries, please contact Customer Services:<br/>
                                                        <strong>{customerServicesLink}</strong><br/><br/>
                                                        Customer Services <a href="tel:+6434729876">+64 3 472 9876</a> (Mon-Fri, Hrs 8:30 – 17:00)<br/>
                                                        24 Hour General Enquiries <a href="tel:+6434727890">+64 3 472 7890</a><br/>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                    :
                                    DefaultIssueMessage()
                                }
                            </Form>
                            :
                            <>
                                <Alert variant="success">
                                    <p>Stop confirmation has been sent and the import storage stop will be removed shortly. Please attempt to make the booking again in 10 minutes.</p>
                                </Alert>
                                <FieldSummary
                                    branchesData={branchesData}
                                    branchesLoading={branchesLoading}
                                    carriersData={carriersData}
                                    carriersLoading={carriersLoading} />
                            </>

                    }

                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    completed && mailSent &&
                    <>
                        <Button variant={props.cancelVariant} onClick={cancelModal}>Go Back</Button>
                    </>
                }
                {
                    !completed && importStop &&
                    <>
                        <FormButton loading={props.confirmLoading} variant={props.confirmVariant} onClick={handleSubmit} onConfirm={props.onConfirm}>{props.confirmText}</FormButton>
                        <Button variant={props.cancelVariant} onClick={cancelModal}>{props.cancelText}</Button>
                    </>
                }
                {
                    !completed && !importStop &&
                    <>
                        <Button variant={props.cancelVariant} onClick={cancelModal}>Go Back</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
};

StopConfirmModal.propTypes = {
    show: PropTypes.bool,
    user: PropTypes.string,
    alert: PropTypes.node,
    onConfirm: PropTypes.func,
    closeModal: PropTypes.func,
    message: PropTypes.string,
    cancelText: PropTypes.string,
    cancelVariant: PropTypes.string,
    confirmText: PropTypes.string,
    confirmVariant: PropTypes.string,
    confirmLoading: PropTypes.bool
};

StopConfirmModal.defaultProps = {
    show: false,
    user: 'User',
    cancelText: "Cancel",
    cancelVariant: "light",
    confirmText: "Confirm",
    confirmVariant: "primary",
    confirmLoading: false
};

export default StopConfirmModal;
