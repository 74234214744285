import axios from 'axios';
import {application} from './application';

export default async function stopEmail(container_number,carrier,user) {
    let apiResponse = {};

    const headers = {
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.request({
            method: 'post',
            url: `${application.application_stop_mail_domain}/mail.php`,
            headers,
            data: {
                container_number: container_number,
                carrier: carrier,
                user: user
            }
        });

        apiResponse = {
            success: response.data.status === 'success',
            payload: response.data.message
        };

    } catch(error) {
        apiResponse = {
            success: false,
            error: {
                message: 'Error'
            }
        };
    }
    return apiResponse;
}
