import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {useCreateBooking} from "../../../util/bookings";
import FormButton from "../../../components/forms/FormButton";
import {Button} from "react-bootstrap";
import ConfirmModal from "../../../components/modals/ConfirmModal";

import "./styles.scss";
import FieldSummary from "../FieldSummary";

const CreateBookingSidebar = ({carriersLoading, carriersData, branchesLoading, branchesData}) => {
    const {validateBooking, validateLoading, resetBooking} = useCreateBooking();

    const [showConfirmClearModal, setShowConfirmClearModal] = useState(false);

    const confirmHandler = useCallback(() => {
        resetBooking();
        setShowConfirmClearModal(false);
    }, [resetBooking, setShowConfirmClearModal]);


    return (
        <div className="create-booking-sidebar">
            <div className="d-none d-md-block">
                <h3>Booking Details</h3>
                <FieldSummary
                    branchesData={branchesData}
                    branchesLoading={branchesLoading}
                    carriersData={carriersData}
                    carriersLoading={carriersLoading} />
            </div>
            <div className="create-booking-sidebar-buttons">
                <FormButton loading={validateLoading} variant="primary" onClick={validateBooking}>Validate Booking</FormButton>{' '}
                <Button variant="light" disabled={validateLoading} onClick={() => setShowConfirmClearModal(true)}>Clear</Button>
                <ConfirmModal
                    show={showConfirmClearModal}
                    message="Current booking options will be cleared. Are you sure you want to continue?"
                    closeModal={() => setShowConfirmClearModal(false)}
                    onConfirm={confirmHandler} />
            </div>
        </div>
    );
};

CreateBookingSidebar.propTypes = {
    carriersLoading: PropTypes.bool.isRequired,
    carriersData: PropTypes.array,
    branchesLoading: PropTypes.bool.isRequired,
    branchesData: PropTypes.array
};

export default CreateBookingSidebar;
