import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from "moment";
import {Alert, Button} from "react-bootstrap";
import {useCreateBooking} from "../../../util/bookings";
import CreateBookingSection from "../CreateBookingSection";

import "./styles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";

const SelectSlot = () => {

    const {bookingState, setBookingSlot} = useCreateBooking();

    let branch_alert, morning_slots, afternoon_slots, evening_slots;


    if (bookingState.bookingdate) {
        branch_alert = bookingState.bookingdate.branch_alert;
        let slots = bookingState.bookingdate.slots
            .filter(slot => slot.direction === bookingState.direction || slot.direction === 'Both')
            .map(slot => ({
                ...slot,
                availableqty: slot.maxqty - slot.bookedqty < 0 ? 0 : slot.maxqty - slot.bookedqty,
                description: slot.description.toLowerCase(),
                active: !_.isEmpty(bookingState.bookingslot) && bookingState.bookingslot.hour === slot.hour && bookingState.bookingslot.number === slot.number
            }));

        const now = moment();

        if (now.format('DD-MM-YYYY') === bookingState.bookingdate.date.base) {
            slots = slots.filter(slot => slot.hour >= now.format("HH"));
        }

        morning_slots = slots.filter(slot => slot.hour < 12);
        afternoon_slots = slots.filter(slot => slot.hour >= 12 && slot.hour < 17);
        evening_slots = slots.filter(slot => slot.hour >= 17);
    }

    return (
        <CreateBookingSection title="Time Slot">
            {
                _.isNil(bookingState.bookingdate) ?
                    <Alert variant="warning">Select a date before choosing a time slot</Alert>
                    :
                    !bookingState.bookingdate.slots.length ?
                        <Alert variant="danger">There are no time slots available for this date</Alert>
                        :
                        <>
                            {
                                !!branch_alert &&
                                    <Alert variant="info">
                                        {
                                            branch_alert.split('.').map((sentence,index) => <p key={index}>{sentence}</p>)
                                        }
                                    </Alert>
                            }
                            {
                                morning_slots.length || afternoon_slots.length || evening_slots.length ?
                                    <div className="row">
                                        {
                                            !!morning_slots.length &&
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <h4 key={`morning-title`}>Morning</h4>
                                                <div className="create-booking-slot-buttons">
                                                    {
                                                        morning_slots.map((slot, i) => (
                                                            <>
                                                                <SlotButton
                                                                    key={`slot-morning-${i}`}
                                                                    disabled={slot.availableqty < bookingState.qty}
                                                                    slot={slot}
                                                                    setBookingSlot={setBookingSlot} />
                                                            </>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !!afternoon_slots.length &&
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <h4 key={`afternoon-title`}>Afternoon</h4>
                                                <div className="create-booking-slot-buttons">
                                                    {
                                                        afternoon_slots.map((slot, i) => (
                                                            <>
                                                                <SlotButton
                                                                    key={`slot-afternoon-${i}`}
                                                                    disabled={slot.availableqty < bookingState.qty}
                                                                    slot={slot}
                                                                    setBookingSlot={setBookingSlot} />
                                                            </>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !!evening_slots.length &&
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <h4 key={`evening-title`}>Evening</h4>
                                                <div className="create-booking-slot-buttons">
                                                    {
                                                        evening_slots.map((slot, i) => (
                                                            <>
                                                                <SlotButton
                                                                    key={`slot-evening-${i}`}
                                                                    prime={`slot-evening-${i}`}
                                                                    disabled={slot.availableqty < bookingState.qty}
                                                                    slot={slot}
                                                                    setBookingSlot={setBookingSlot} />
                                                            </>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <Alert variant="danger">There are no time slots available for this date</Alert>
                            }
                            <div className={`invalid-feedback ${!!bookingState.errors.bookingslot ? 'd-block' : ''}`}>
                                <FontAwesomeIcon icon={faExclamationTriangle} /> {bookingState.errors.bookingslot}
                            </div>
                        </>
            }
        </CreateBookingSection>
    );
};

const SlotButton = ({prime, slot, disabled, setBookingSlot}) => {
    return (
        <>
            <Button key={prime} disabled={disabled}
                    variant={slot.active ? 'primary' : 'light'}
                    onClick={() => setBookingSlot(slot)}>
                <div className="create-booking-slot-description">{slot.description}</div>
                <div className={`create-booking-slot-availability ${slot.availableqty > 0 ? 'available' : 'full'}`}>
                    {slot.availableqty} available
                </div>
            </Button>
        </>
    );
};

SlotButton.propTypes = {
    slot: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    setBookingSlot: PropTypes.func.isRequired
};

export default SelectSlot;
