import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from "moment";
import {Alert, Button} from "react-bootstrap";
import CreateBookingSection from "../../bookings-new/CreateBookingSection";

import "./styles.scss";

const UpdateSlot = ({booking, bookingDate, setBookingSlot}) => {

    let morning_slots, afternoon_slots, evening_slots;

    if (bookingDate) {
        let slots = bookingDate.slots
            .filter(slot => slot.direction === booking.slotdirection || slot.direction === 'Both')
            .map(slot => ({
                ...slot,
                availableqty: slot.maxqty - slot.bookedqty < 0 ? 0 : slot.maxqty - slot.bookedqty,
                description: slot.description.toLowerCase(),
                active: !_.isEmpty(booking.bookingslot) && booking.bookingslot.hour === slot.hour && booking.bookingslot.number === slot.number
            }));
        const now = moment();
        if (now.format('DD-MM-YYYY') === bookingDate.date.base) {
            let hourText = now.format("HH");
            let minuteText = now.format("mm");
            if(booking.branchcode !== 'PC'){
                if (minuteText >= 30){
                    hourText = now.add(1,'hours').format("HH");
                }
            }
            slots = slots.filter(slot => slot.hour >= hourText);
        }

        morning_slots = slots.filter(slot => slot.hour < 12);
        afternoon_slots = slots.filter(slot => slot.hour >= 12 && slot.hour < 17);
        evening_slots = slots.filter(slot => slot.hour >= 17);
    }

    return (
        <CreateBookingSection title="Time Slot">
            {
                _.isNil(bookingDate) ?
                    <Alert variant="warning">Select a date before choosing a time slot</Alert>
                    :
                    !bookingDate.slots.length ?
                        <Alert variant="danger">There are no time slots available for this date</Alert>
                        :
                        <>
                            {/*{
                                !!branch_alert &&
                                    <Alert variant="info">
                                        {
                                            branch_alert.split('.').map((sentence,index) => <p key={index}>{sentence}</p>)
                                        }
                                    </Alert>
                            }*/}
                            {
                                morning_slots.length || afternoon_slots.length || evening_slots.length ?
                                    <div className="row">
                                        {
                                            !!morning_slots.length &&
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <h4 key={`morning-title`}>Morning</h4>
                                                <div className="create-booking-slot-buttons">
                                                    {
                                                        morning_slots.map((slot, i) => (
                                                            <>
                                                                <UpdateSlotButton
                                                                    key={`slot-morning-${i}`}
                                                                    disabled={slot.availableqty < booking.qty}
                                                                    slot={slot}
                                                                    setBookingSlot={setBookingSlot} />
                                                            </>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !!afternoon_slots.length &&
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <h4 key={`afternoon-title`}>Afternoon</h4>
                                                <div className="create-booking-slot-buttons">
                                                    {
                                                        afternoon_slots.map((slot, i) => (
                                                            <>
                                                                <UpdateSlotButton
                                                                    key={`slot-afternoon-${i}`}
                                                                    disabled={slot.availableqty < booking.qty}
                                                                    slot={slot}
                                                                    setBookingSlot={setBookingSlot} />
                                                            </>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !!evening_slots.length &&
                                            <div className="col-12 col-sm-4 col-md-4">
                                                <h4 key={`evening-title`}>Evening</h4>
                                                <div className="create-booking-slot-buttons">
                                                    {
                                                        evening_slots.map((slot, i) => (
                                                            <>
                                                                <UpdateSlotButton
                                                                    key={`slot-evening-${i}`}
                                                                    prime={`slot-evening-${i}`}
                                                                    disabled={slot.availableqty < booking.qty}
                                                                    slot={slot}
                                                                    setBookingSlot={setBookingSlot} />
                                                            </>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <Alert variant="danger">There are no time slots available for this date</Alert>
                            }
                        </>
            }
        </CreateBookingSection>
    );
};

const UpdateSlotButton = ({prime, slot, disabled, setBookingSlot}) => {
    return (
        <>
            <Button key={prime} disabled={disabled}
                    variant={slot.active ? 'primary' : 'light'}
                    onClick={() => setBookingSlot(slot)}>
                <div className="create-booking-slot-description">{slot.description}</div>
                <div className={`create-booking-slot-availability ${slot.availableqty > 0 ? 'available' : 'full'}`}>
                    {slot.availableqty} available
                </div>
            </Button>
        </>
    );
};

UpdateSlotButton.propTypes = {
    slot: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    setBookingSlot: PropTypes.func.isRequired
};

export default UpdateSlot;
