import React, {useEffect} from "react";
import PageTitle from "../../components/layout/PageTitle";
import {Alert} from "react-bootstrap";

import SelectDepot from "./SelectDepot";
import SelectContainerDirection from "./SelectContainerDirection";
import EnterQuantity from "./EnterQuantity";
import EnterReleaseNumber from "./EnterReleaseNumber";
import EnterContainerNumber from "./EnterContainerNumber";
import SelectSlot from "./SelectSlot";
import SelectDate from "./SelectDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {useCreateBooking, ProvideCreateBooking} from "../../util/bookings";

import "./styles.scss";
import CreateBookingSidebar from "./CreateBookingSidebar";
import _ from "lodash";
import SelectContainerPositioning from "./SelectContainerPositioning";
import SelectContainerDetails from "./SelectContainerDetails";
import SelectCarrier from "./SelectCarrier";
import SaveBookingModal from "./SaveBookingModal";
import {ScrollToTop} from "../../util/scroll-to-top";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import {useBranchesData, useCarriersData} from "../../util/data-hooks";
import {getQueryParams} from "../../util/query-params";
import {useLocation} from "react-router-dom";
import StopConfirmModal from "../../components/modals/StopConfirmModal";

const CreateBookingPage = () => <ProvideCreateBooking><CreateBookingPageContent /></ProvideCreateBooking>;

const CreateBookingPageContent = () => {
    const location = useLocation(),
        [branchesLoading, branchesData, branchesError] = useBranchesData(true),
        [carriersLoading, carriersData, carriersError] = useCarriersData();

    const {
        bookingState,
        showSaveModal,
        setShowSaveModal,
        showStopModal,
        setShowStopModal,
        fieldLabels,
        setBranchCode,
        user
    } = useCreateBooking();

    let errorList = [];

    if (!_.isEmpty(bookingState.errors)) {
        if(bookingState.branchcode !== 'PC'){
            if(!bookingState.errors.stops){
                for (const field in bookingState.errors) {
                    if (bookingState.errors.hasOwnProperty(field)) errorList.push({field: _.get(fieldLabels, field, field), error: bookingState.errors[field]});
                }
            }
        }
    }

    useEffect(() => {
        const {depot} = getQueryParams(location.search, ['depot']);
        if (depot) setBranchCode(depot);
    }, [setBranchCode, location.search]);

    return (
        <>
            <PageTitle title="Create Booking" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7 col-lg-8 col-xl-9">
                        {
                            !!errorList.length &&
                                <>
                                    <Alert variant="danger" className="form-errors">
                                        <p><strong>Please correct the following errors:</strong></p>
                                        <ul>
                                            {
                                                errorList.map(({field, error}, i) => (
                                                    <li key={`error-${i}`}>
                                                        <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                                                        <strong>{field}:</strong>{' '}
                                                        {error}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </Alert>
                                    <ScrollToTop />
                                </>
                        }

                        <div className="row">
                            <div className="col-lg-6">
                                <SelectDepot branchesLoading={branchesLoading} branchesData={branchesData} branchesError={branchesError} />
                            </div>
                            <div className="col-lg-6">
                                <SelectCarrier carriersLoading={carriersLoading} carriersData={carriersData} carriersError={carriersError} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <SelectContainerDirection />
                            </div>
                            <div className="col-lg-6">
                                <EnterQuantity />
                            </div>
                        </div>

                        <div className="row">
                            {
                                (bookingState.direction === 'IN' || (bookingState.direction === 'OUT' && bookingState.branchcode === 'PC')) &&
                                    <div className="col-lg-6">
                                        <EnterContainerNumber />
                                    </div>
                            }
                            {
                                bookingState.direction === 'OUT' &&
                                    <div className="col-lg-6">
                                        <EnterReleaseNumber />
                                    </div>
                            }
                        </div>

                        {
                            !!bookingState.branchcode && !!bookingState.direction &&
                                <div className="row">
                                    <div className="col-12">
                                        <SelectContainerDetails />
                                    </div>
                                </div>
                        }

                        <div className="row">
                            <div className="col-12">
                                <SelectContainerPositioning />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <SelectDate />
                            </div>
                            <div className="col-lg-8">
                                <SelectSlot />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <CreateBookingSidebar branchesLoading={branchesLoading}
                                              branchesData={branchesData}
                                              carriersLoading={carriersLoading}
                                              carriersData={carriersData}
                        />
                        <StopConfirmModal show={showStopModal}
                                          user={user.name}
                                          closeModal={() =>
                                              setShowStopModal(false)}
                                          message="There are issues/holds which prevent you from making this booking"
                                          bookingState={bookingState}
                                          branchesLoading={branchesLoading}
                                          branchesData={branchesData}
                                          carriersLoading={carriersLoading}
                                          carriersData={carriersData}
                                          />
                        <SaveBookingModal branchesLoading={branchesLoading}
                                          branchesData={branchesData}
                                          carriersLoading={carriersLoading}
                                          carriersData={carriersData}
                                          show={showSaveModal}
                                          handleClose={() =>
                            setShowSaveModal(false)} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateBookingPage;
