import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import StopFormContent from "../../components/modals/StopForm";
import {APICall} from "../../util/api";

const StopFormPage = () => {
    const [modal, setModal] = useState(false);
    const [result, setResult] = useState("");
    const [testBooking, setTestBooking] = useState([]);

    const handleShow = () => setModal(true);
    const handleClose = () => setModal(false);

    const sendEmail = (e) => {
        e.preventDefault();
        let formData = new FormData();
        Axios({
            method: 'POST',
            url: "/mail.php",
            headers: { 'content-type': 'application/json' },
            data: formData
        })
        .then((res) => {
            setResult(res.data)
        });
    }

    const bookings = () => {
      testBooking.forEach(element => {
        if(element.slotDirection === "OUT")
        {
          console.log(element)
        }
        else {
          console.log(element)
        }
      });
    }
    bookings();

    useEffect(() => {
    //   APICall({
    //     method: 'post',
    //     url: '?MessageType=GetCarriers',
    //     data: {id: 141}
    //   })
    //   .then((res) => {
    //     console.log(res.data.carriers[0]);
    //     setTestCarrier(res.data.carriers[0]);
      //});
      APICall({
        method: 'post',
        url: '?MessageType=GetBookings',
        data: {id: 141, fromticks: 638027712000000000, toticks: 638136575990000000}
      })
      .then((res) => {
        setTestBooking(res.data.bookings);
      });
      APICall({
        method: 'post',
        url: '?MessageType=PreArrivalContainerEnquiry',
        data: {id: 141, branchcode: "SF", containerno: "TCKU1149026"}
      })
      .then((res) => {
        console.log(res.data);
      });
    }, [])

    return(
        <>
            <Button onClick={handleShow}>Modal</Button>
            <form method="post" action="http://localhost:8000/index.php" onSubmit={(event) => sendEmail(event)}>
                <button type="submit" name="submit">Email Test</button>
            </form>

            <StopFormContent show={modal} hide={handleClose}></StopFormContent>

            <div dangerouslySetInnerHTML={{__html: result}}></div>
        </>
    )
}

export default StopFormPage;